<template>
  <section class="all-documents-view">
    <el-row :type="getIsMobile ? '' : 'flex'" justify="left" align="left">
      <el-col v-if="getIsMobile" :xl="8" :lg="8" :md="12" :sm="12" :xs="12">
          <h3 class="title fw-normal text-dark mb-0 ls-05" :style="getIsMobile ? 'margin-top:10px;margin-left:2px' : ''">Documents</h3>
        </el-col>
      <el-col v-if="!getIsMobile" :xl="8" :lg="8" :md="6" :sm="12" :xs="12">
        <el-col :xl="6" :lg="6" :md="12" :sm="12" :xs="12">
          <h3 class="title fw-normal text-dark mb-0 ls-05" style="width: 200px;">Request Documents</h3>
        </el-col>
      </el-col>
    </el-row>
    <div class="document-types">
      <h5 class="title fw-normal text-dark mb-0 ls-05" style="width: 200px;">Document types</h5>
    </div>
    <div class="document-types-item-wrapper">
      <el-row justify="left" align="left" :gutter="30">
        <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="24">
          <div
            class="document-types-item"
            @click="handleCardItemClicked(1)"
          >
            <div class="document-types-item-left">
              <div class="img-wrapper">
                <img src="@/assets/img/icons/card-type/card.svg" alt="card" />
              </div>
            </div>
            <div class="document-types-item-right">
              Driver's license (USA)
            </div>
          </div>
        </el-col>
        <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="24">
          <div
            class="document-types-item"
            @click="handleCardItemClicked(2)"
          >
            <div class="document-types-item-left">
              <div class="img-wrapper">
                <img src="@/assets/img/icons/card-type/Void Cheque.svg" alt="card" />
              </div>
            </div>
            <div class="document-types-item-right">
              Void Check
            </div>
          </div>
        </el-col>
        <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="24">
          <div
            class="document-types-item"
            @click="handleCardItemClicked(3)"
          >
            <div class="document-types-item-left">
              <div class="img-wrapper">
                <img src="@/assets/img/icons/card-type/Passport.svg" alt="card" />
              </div>
            </div>
            <div class="document-types-item-right">
              Passports
            </div>
          </div>
        </el-col>
        <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="24">
          <div
            class="document-types-item"
            @click="handleCardItemClicked(4)"
          >
            <div class="document-types-item-left">
              <div class="img-wrapper">
                <img src="@/assets/img/icons/card-type/I-94.svg" alt="card" />
              </div>
            </div>
            <div class="document-types-item-right">
              I-94
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row justify="left" align="left" :gutter="30" style="margin-top: 0px;">
        <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="24">
          <div
            class="document-types-item"
            @click="handleCardItemClicked(5)"
          >
            <div class="document-types-item-left">
              <div class="img-wrapper">
                <img src="@/assets/img/icons/card-type/Aadhar Card.svg" alt="card" />
              </div>
            </div>
            <div class="document-types-item-right">
              Aadhar Cards
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
// import { bus } from "../../main";
import { mapGetters } from "vuex";
import PermissionsHelper from "@/mixins/permissionsHelper";
export default {
  name: "companyDocuments-AllDocuments",
  props: ["pageSizes", "type", "categoryId", "groupId"],
  data() {
    return {
      is_documentDelete: false,
      dialoguevisibility: false,
      centerDialogVisible: false,
      isVisible: false,
      selected_document: [],
      loading: false,
      search_string: "",
      editDailogVisible: false,
      selected_company_user: "",
      companyUsers: [],
      selectedCompanyDocument: "",
      total: 0,
      currentPage: 1,
      pageSize: 10,
      page: 1,
      configureLoading: false,
      openSendDocumentsModal: false,
      documentUsers: [],
      showSendDocumentoUserSettings: false,
      enforceSignatureOrder: null,
      documents: [],
      modalData: {
        document_id: "",
        name: "",
        action: "",
      },
      documentActionModal: false,
      modalLoading: false,
      selectedStatus: "",
      statusOption: [
        { name: "Draft", value: "draft-docs" },
        { name: "Sent", value: "sent-docs" },
        { name: "Approved", value: "approved" },
        { name: "Company Documents", value: "company-documents" },
        { name: "Declined", value: "declined" },
        { name: "Voided", value: "voided" },
        { name: "Archived", value: "deleted" },
        { name: "Completed", value: "completed" },
        { name: "Waiting for Me", value: "waiting-for-me" },
        { name: "Waiting for Others", value: "waiting-for-others" },
        { name: "Expired", value: "expired" },
        { name: "Expiring Soon", value: "expiring-soon" },
      ],
      statusOptionIndividual: [
        { name: "Draft", value: "draft-docs" },
        { name: "Sent", value: "sent-docs" },
        { name: "Approved", value: "approved" },
        { name: "Declined", value: "declined" },
        { name: "Voided", value: "voided" },
        { name: "Archived", value: "deleted" },
        { name: "Completed", value: "completed" },
        { name: "Waiting for Me", value: "waiting-for-me" },
        { name: "Waiting for Others", value: "waiting-for-others" },
        { name: "Expired", value: "expired" },
        { name: "Expiring Soon", value: "expiring-soon" },
      ],
    };
  },
  mixins: [PermissionsHelper],
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getUserType",
      "getActiveWorkspace",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("documents", ["getAllCompanyUsers"]),
    getSelecetedDocStatus() {
      if (this.selecetedDocStatus == "waiting") {
        return "Need to be Sign";
      }
      return this.selecetedDocStatus;
    },
  },
  mounted() {
    if (this.$route && this.$route.query && this.$route.query.type) {
      this.selectedStatus = this.$route.query.type;
    }
    // this.getCompanyUsers();
  },
  methods: {
    handleCardItemClicked(cardTypeNo) {
      // 1: Driver's license (USA)
      // 2: Void Check
      // 3: Passports
      // 4: I-94
      // 5: Aadhar Cards
      this.$router.push({ path: "/request-documents/all/" + cardTypeNo });
    }
  },
};
</script>

<style lang="scss" scoped>
.document-types-item-wrapper {
  margin-top: 25px;
  .document-types-item {
    margin-top: 15px;
    cursor: pointer;
    border: 1px solid #e4e7ec;
    border-radius: 3px;
    display: flex;
    width: 100%;
    justify-content: center;
    height: 65px;
    align-items: center;
    .document-types-item-left {
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      .img-wrapper {
        border-radius: 25px;
        background: #ecf5ff;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .document-types-item-right {
      margin-left: 13px;
      width: 70%;
    }
  }
}
.document-types {
  margin-top: 20px;
}
.all-documents-view {
  .search {
    position: relative;
    // right: -199px;
    // width: 200px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .select {
    position: relative;
    // right: -199px;
    // width: 200px;
    @media (max-width: 991.98px) {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .title {
    letter-spacing: 0.019em;
  }
  .text-secondary-sent {
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    text-align: left !important;
    color: #439ffc !important;
  }
  .text-secondary {
    font-size: 14px !important;
    font-weight: 400 !important;
    // line-height: 14px !important;
    text-align: left !important;
    color: #6c757d !important;
  }
  .doc-subs {
    font-size: 10px;
    margin-top: -13px;
    font-weight: 300;
    text-align: right;
    margin-right: 9%;
  }
  .doc-sub {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    //margin-bottom:-18px;
    text-align: left;
  }
  .doc-title {
    cursor: pointer;
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 14px;
    text-align: left;

    color: #6c757d !important;
  }
  .check-font {
    color: #6c757d !important;
    font-family: "Inter", sans-serif;
  }
  .circle {
    position: relative;
    padding-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
    &::after {
      background: #635e5e;
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      height: 10px;
      width: 10px;
      display: inline-block;
      border-radius: 50%;
    }
    &.completed::after {
      background: #08ad36;
    }
    &.draft::after {
      background: #f19203;
    }
    &.sent::after {
      background: #2f80ed;
    }
    &.active::after {
      background: #635e5e;
    }
    &.expired::after {
      background: #f03232;
    }
  }
  .custom-pagination {
    text-align: center;
    margin-top: 5px;
  }
}
</style>

<style lang="scss">
.doc-modal {
  .el-dialog__header {
    .el-dialog__title {
      text-transform: uppercase;
    }
  }
  .error {
    color: red;
  }
}
.import-dropdown-btn {
  margin-left: 10px;
  height: 40px;
  .el-button {
    height: 100%;
    font-size: 14px;
    span {
      padding: 0 10px;
    }
  }
}
.import-dropdown-btn-menu {
  .el-dropdown-menu__item {
    display: flex;
    img {
      width: 25px;
      height: 25px;
      padding: 3px;
      background: #f7f7f7;
      border-radius: 10px;
    }
    span {
      margin-top: -2px;
      margin-left: 10px;
    }
  }
}
.DialogueStyle {
  margin-top: 50px;
}
</style>
